/**
 * Created by andyosullivan on 04/04/2021.
 */
import React, { Component } from "react";
import "./Home.css";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image';
import appicon from '../assets/images/appicon.png';
import insta from '../assets/images/instagram.png';
import twitter from '../assets/images/twitter.png';
import fb from '../assets/images/fb.png';

export default class Privacy extends Component {

    componentDidMount () {
        this.deleteAllCookies();
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear()
        sessionStorage.clear();
    }

    render() {
        return (
            <div className="divmor">
                <Container>

                    <Row className="blurbRow">

                        <Col className="screens7" sm={12} lg={4}>
                            <p className="blurb">
                                <h2>Privacy Policy</h2>
                                <span className="kids"></span>we <span className="fungame">collect zero data<br/> from the app</span> or
                                <span className="fungame"> this website </span>
                                <br/>Seriously - <span className="kids">no cookies, no tracking, nothing! </span>we
                                <span className="boxapopa"> believe in privacy.</span>
                            </p>
                            <Image src={appicon} alt="app store icon" className="store"/>
                            <p className="blurb">
                                <br/>
                                <span className="kids">support: </span> email us at <a className="links" href="mailto:eolas@toraiocht.com">eolas@toraiocht.com</a>
                                <br/><br/>

                                <a href="https://www.instagram.com/boxapopa/" target="_blank" rel="noreferrer"><img className="social" src={insta} alt="instragram logo"/></a>
                                <a href="https://twitter.com/boxapopa" target="_blank" rel="noreferrer"><img className="social" src={twitter} alt="twitter logo"/></a>
                                <a href="https://www.facebook.com/Boxapopa-112205847634954" target="_blank" rel="noreferrer"><img className="social" src={fb} alt="facebook logo"/></a>
                                <br/><br/>
                                ©Toraíocht Studios 2023


                            </p>

                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}/**
 * Created by andyosullivan on 06/10/2021.
 */
