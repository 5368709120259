/**
 * Created by andyosullivan on 04/04/2021. ok?
 */
import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from './containers/Home';
import Privacy from './containers/Privacy';
import Support from './containers/Support';
import PrivacyPolicy from "./containers/PrivacyPolicy";

export default function Routes(){
    return (
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/support" component={Support} />
            <Route path="/privacypolicy" component={PrivacyPolicy} />
        </Switch>
    );
}


