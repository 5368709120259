/**
 * Created by andyosullivan on 04/04/2021.
 */
import React, { Component } from "react";
import "./Home.css";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image';
import appicon from '../assets/images/carAppLogo.png';

export default class Support extends Component {

    componentDidMount () {
        this.deleteAllCookies();
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear()
        sessionStorage.clear();
    }

    render() {
        return (
            <div className="divmor">
                <Container>

                    <Row className="blurbRow">

                        <Col className="screens7" sm={12} lg={4}>
                            <p className="blurb">
                                <h2>Support</h2>
                            </p>
                            <Image src={appicon} alt="app store icon" className="store"/>
                            <p className="blurb">
                                <br/>
                                <span className="kids">support: </span> email us at <a className="links" href="mailto:eolas@toraiocht.com">eolas@toraiocht.com</a>
                                <br/><br/>

                                 <br/><br/>
                                ©Toraíocht Studios 2022


                            </p>

                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}/**
 * Created by andyosullivan on 06/10/2021.
 */
