/**
 * Created by andyosullivan on 04/04/2021.
 */
import React, { Component } from "react";
import "./Home.css";
import logo from "../assets/images/boxapopaname.png";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import appStoreBadge from "../assets/images/app-store-badge.svg";
import Image from 'react-bootstrap/Image';
import screen1 from '../assets/images/screen1b.png';
import screen2 from '../assets/images/screen2a.png';
import screen3 from '../assets/images/screen3a.png';
import screen4 from '../assets/images/screen4a.png';
import appicon from '../assets/images/appicon.png';
import Card from 'react-bootstrap/Card';
import insta from '../assets/images/instagram.png';
import twitter from '../assets/images/twitter.png';
import fb from '../assets/images/fb.png';

export default class Home extends Component {

    componentDidMount () {
        this.deleteAllCookies();
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear()
        sessionStorage.clear();
    }

    goToAppStore(){

    }

    render() {
        return (
            <div className="divmor">
                <Container>

                    <Row className="blurbRow">


                    <Col className="screens" sm={12} lg={3}>
                        <img src={logo} alt="logo" className="box-logo"/><br/>

                        <Image src={appicon} alt="app store icon" className="store"/>

                        <Card className="blurbCard">

                            <p className="blurb"><span className="boxapopa">boxapopa</span> is a
                                <br/><span className="fungame">fun game</span> for <span className="kids">young kids!</span>
                                <br/>each time they <span className="boxapopa">tap
                                    the <br/>box</span> something else<br/><span className="fungame">appears to play with!</span> </p>
                            <a href="https://apps.apple.com/ie/app/boxapopa/id1550536188" target="_blank" rel="noreferrer"><Image src={appStoreBadge} alt="app store icon" className="storeicon" fluid/></a>
                            <br/>
                        </Card>
                    </Col>
                        <Col className="screens1" sm={12} lg={3}>
                           <Image src={screen1} alt="screenshot of app" className="screenshots" fluid/>
                        </Col>
                        <Col className="screens2" sm={12} lg={3}>
                            <p className="blurb">
                                <span className="boxapopa">boxapopa </span>is <span className="fungame">free to download </span>
                                with <span className="fungame">no ads! </span> Because ads are annoying
                                and <span className="kids">boxapopa </span>is all <span className="fungame">about the fun!</span>
                            </p>
                            <Image src={appicon} alt="app store icon" className="store"/>
                            <p className="blurb">
                                <br/>you get <span className="kids">50 boxes to pop for free</span>, and can
                                <span className="boxapopa"> buy more if you want</span> via in-app purchase.
                                <span className="fungame"> You don't get hassled</span> to buy more though, it's not that sort of game!

                            </p>
                        </Col>
                        <Col className="screens3" sm={12} lg={3}>
                            <Image src={screen2} alt="screenshot of app" className="screenshots" fluid/>
                        </Col>
                        <Col className="screens4" sm={12} lg={3}>
                            <Image src={screen3} alt="screenshot of app" className="screenshots" fluid/>
                        </Col>
                        <Col className="screens5" sm={12} lg={3}>
                            <p className="blurb">
                                <span className="boxapopa">boxapopa </span>was created <span className="fungame">during lockdown </span>
                                to <span className="kids">entertain our kids </span> and
                                because we were <span className="boxapopa">fed up of all the games with ads </span>
                                so we <span className="fungame"> decided to make our own!</span>
                                <br/><br/>
                                Designed and built by <span className="kids">Toraíocht Studios</span>, an indie games company in Ireland<br/><br/>

                                Uses a lot of images under license from the amazing <a className="links" href="https://icons8.com/" target="_blank" rel="noreferrer">icons8.com</a>
                                <br/><br/>
                                And a lot of sounds under license from the great
                                <a className="links" href="https://www.zapsplat.com/" target="_blank" rel="noreferrer"> zapsplat.com</a>

                            </p>
                        </Col>
                        <Col className="screens6" sm={12} lg={3}>
                            <Image src={screen4} alt="screenshot of app" className="screenshots" fluid/>
                        </Col>
                        <Col className="screens7" sm={12} lg={3}>
                            <p className="blurb">
                                <span className="kids">privacy: </span>we <span className="fungame">collect zero data<br/> from the app</span> or
                                <span className="fungame"> this website </span>
                                <br/>Seriously - <span className="kids">no cookies, no tracking, nothing! </span>we
                                <span className="boxapopa"> believe in privacy.</span>
                            </p>
                            <Image src={appicon} alt="app store icon" className="store"/>
                            <p className="blurb">
                                <br/>
                                <span className="kids">support: </span> email us at <a className="links" href="mailto:eolas@toraiocht.com">eolas@toraiocht.com</a>
                                <br/><br/>

                                <a href="https://www.instagram.com/boxapopa/" target="_blank" rel="noreferrer"><img className="social" src={insta} alt="instragram logo"/></a>
                                <a href="https://twitter.com/boxapopa" target="_blank" rel="noreferrer"><img className="social" src={twitter} alt="twitter logo"/></a>
                                <a href="https://www.facebook.com/Boxapopa-112205847634954" target="_blank" rel="noreferrer"><img className="social" src={fb} alt="facebook logo"/></a>
                                <br/><br/>
                                ©Toraíocht Studios 2021


                            </p>

                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}