/**
 * Created by andyosullivan on 04/04/2021.
 */
import React, { Component } from "react";
import "./Home.css";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image';
import streetLinkLogo from '../assets/images/streetlink.png';
import "./privacypolicy.css";

export default class PrivacyPolicy extends Component {

    componentDidMount () {
        this.deleteAllCookies();
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear()
        sessionStorage.clear();
    }

    render() {
        return (
            <div className="divmor">
                <Container>

                    <Row className="blurbRow">

                        <Col className="screens7" sm={12} lg={4}>
                            <p className="blurb">
                                <h2>Street Link App Privacy Policy</h2>
                                We collect zero data from the app
                                apart from the data you send yourself via the location features.
                            </p>
                            <Image className="logo" src={streetLinkLogo} alt="app store icon" />
                            <p className="blurb">
                                <br/>
                                <span className="kids">For support: </span> email us at <a className="links" href="mailto:eolas@toraiocht.com">eolas@toraiocht.com</a>
                                <br/><br/>

                                 <br/><br/>
                                ©Toraíocht Studios 2023


                            </p>

                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}/**
 * Created by andyosullivan on 06/10/2021.
 */
