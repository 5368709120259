//import logo from './logo.svg';
import React from "react";
import './App.css';
import Routes from "./Routes";
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
      <div className="App  bg-color">
        <Routes />
      </div>
  );
}

export default App;
